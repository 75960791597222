<template>
  <div class="StaffManage-list">
    <GlobalInfoBar
      title="补拍订单统计"
      content="统计回收商申请补拍的订单数据，包括待补拍、补拍完成、未补拍订单"
    />
    <div class="five_style">
      <div v-for="item in fivelist" :key="item.name">
        <img :src="item.src" alt="" />
        <div>
          <div :style="{ color: item.color }">
            <span
              >{{ item.num
              }}<span style="font-size: 16px; line-height: 16px"
                >（单）</span
              ></span
            >
          </div>
          <div style="display: flex; flex-direction: row; align-items: center">
            <span>{{ item.title }}</span>
            <img
              :src="item.srcs"
              style="width: 16px; height: 16px; margin: 0; margin-left: 8px"
              @click="wenhao(item.title, item.Tips, item.masgsrc)"
            />
          </div>
        </div>
      </div>
    </div>
    <GlobalChunk icon="search" title="筛选查询">
      <div slot="filter">
        <GlobalForm
          class="global-form"
          :init-data="initData"
          :form-item-list="formItemList"
          :inline="true"
          :labelWidth="100"
          round
          @handleConfirm="handleConfirm"
          confirmBtnName="查询"
        >
          <el-form-item
            class="el_from"
            :label-width="'80px'"
            label="总监/区域"
            slot="areaName"
            style="margin:0"
          >
            <el-select
              clearable
              v-model="areaIddata"
              placeholder="请选择总监/区域"
            >
              <el-option
                v-for="item in areagetAreaSelect"
                :key="item.staffId"
                :label="
                  item.areaName
                    ? `${item.staffName}-${item.areaName}`
                    : item.staffName
                "
                :value="item.staffId"
              >
              </el-option>
            </el-select>
          </el-form-item>

          <el-form-item
            class="el_from"
            label="所属门店"
            label-width="68px"
            slot="stores"
            style="margin:0"
          >
            <el-select
              v-model="storesID"
              placeholder="请选择门店"
              filterable
              clearable
            >
              <el-option
                v-for="item in storeList"
                :key="item.key"
                :label="item.value"
                :value="item.key"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="el_from" label-width="48px" label="时间" slot="timeSelect" style="margin:0">
            <el-date-picker
              v-model='entryTime'
              type="daterange"
              :picker-options="pickerOptions"
              :editable="false"
              start-placeholder="开始日期"
              range-separator="~"
              end-placeholder="结束日期"
              :clearable="false"
              :default-time="['00:00:00', '23:59:59']">
            </el-date-picker>
          </el-form-item>
          <el-form-item slot="btn" class="el_from" style="margin:0">
            <el-button
              round
              style="
                width: 100px;
                height: 32px;
                border: 1px solid #c1d1ff;
                border-radius: 16px;
              "
              :type="btntype == '01' ? 'primary' : ''"
              @click="btntypeFn('01')"
              >今日</el-button
            >
            <el-button
              round
              style="
                width: 100px;
                height: 32px;
                border: 1px solid #c1d1ff;
                border-radius: 16px;
              "
              :type="btntype == '02' ? 'primary' : ''"
              @click="btntypeFn('02')"
              >昨日</el-button
            >
            <el-button
              round
              style="
                width: 100px;
                height: 32px;
                border: 1px solid #c1d1ff;
                border-radius: 16px;
              "
              :type="btntype == '03' ? 'primary' : ''"
              @click="btntypeFn('03')"
              >本周</el-button
            >
            <el-button
              round
              style="
                width: 100px;
                height: 32px;
                border: 1px solid #c1d1ff;
                border-radius: 16px;
              "
              :type="btntype == '05' ? 'primary' : ''"
              @click="btntypeFn('05')"
              >本月</el-button
            >
          </el-form-item>
        </GlobalForm>
      </div>
      <!-- <StaffManageTable /> -->
      <div style="margin-bottom: 20px">
        <span>按要求补拍订单顺序：</span>
        <el-select v-model="orderType" @change="orderTypeChange">
          <el-option
            v-for="item in orderTypeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="pageTableLoading"
        :columns="regiondatatitle"
        :data="this.redenvellist"
        :currentPage="homepageName"
        :total="homeToale"
        @handleCurrentChange="Homeform"
      >
        <el-table-column label="所属总监/区域" slot="areaName" align="center">
          <template slot-scope="{ row }">
            <span>{{row.inspectorName}}</span>
            <span v-if="row.areaName">-</span>
            <span>{{ row.areaName }}</span>
            <span v-if="!row.inspectorName && !row.areaName">--</span>
          </template>
        </el-table-column>
        <el-table-column label="所属门店" slot="storeName" align="center">
          <template slot-scope="{ row }">
            <span
              style="
                text-decoration: underline;
                cursor: pointer;
                color: #0981ff;
              "
              @click="storeNameFn(row.storeId)"
              >{{ row.storeName || "--" }}</span
            >
          </template>
        </el-table-column>
      </GlobalTable>
    </GlobalChunk>

    <el-dialog
      class="allot-dialog"
      title="门店补拍数据统计"
      :visible.sync="handleShow"
      :close-on-click-modal="false"
      width="1200px"
      @closed="allotDialogClosed"
    >
      <GlobalForm
        class="global-form"
        :init-data="initData"
        :form-item-list="supplementary"
        :inline="true"
        :labelWidth="100"
        round
        @handleConfirm="Catchupquery"
        confirmBtnName="查询"
      >
      </GlobalForm>
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="Catchup"
        :data="this.Catchuplist"
        :currentPage="StorepageNum"
        :total="Storetotal"
        @handleCurrentChange="Storeform"
      >
        <el-table-column label="申请补拍（单）" slot="demand" align="center">
          <template slot-scope="{ row }">
            <span
              style="
                text-decoration: underline;
                cursor: pointer;
                color: #0981ff;
              "
              @click="demandFn(row)"
              >{{ row.demand }}</span
            >
          </template>
        </el-table-column>

        <el-table-column
          label="店员主动补拍订单（单）"
          slot="initiative"
          align="center"
        >
          <template slot-scope="{ row }">
            <span
              style="
                text-decoration: underline;
                cursor: pointer;
                color: #0981ff;
              "
              @click="activeFn(row)"
              >{{ row.initiative }}</span
            >
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>

    <el-dialog
      class="allot-dialog"
      title="店员待补拍订单"
      :visible.sync="Peoplebelow"
      :close-on-click-modal="false"
      width="1200px"
      @closed="Peoplebelowclose"
    >
      <div class="dangqian">
        当前还有<span style="color: red">{{ waitforlistwait.wait }}</span
        >单待补拍，今日还有<span style="color: red">{{
          waitforlistwait.waitToday
        }}</span
        >单待补拍
      </div>
      <el-form>
        <el-form-item label="补拍状态">
          <el-select
            v-model="value"
            placeholder="请选择"
            @change="applychange"
            @clear="applyclose"
            clearable
          >
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="waitfordata"
        :data="this.waitforlist"
        :currentPage="waitforpageNum"
        :total="waitfortotal"
        @handleCurrentChange="waitforfrom"
      >
        <el-table-column
          label="待补拍示例图"
          slot="waitSampleImgList"
          align="center"
        >
          <template slot-scope="{ row }">
            <div
              class="images-box"
              v-if="row.waitSampleImgList && row.waitSampleImgList.length > 0"
            >
              <img
                :src="row.waitSampleImgList[0]"
                alt=""
                @click="seeImg(row)"
              />
              <span class="text-down"
                >共{{ row.waitSampleImgList.length }}张</span
              >
            </div>
            <div v-else>暂无图片</div>
          </template>
        </el-table-column>

        <el-table-column label="申请补拍记录" slot="apply" align="center">
          <template slot-scope="{ row }">
            <span
              style="
                text-decoration: underline;
                cursor: pointer;
                color: #0981ff;
              "
              @click="applyFn(row)"
              >{{ row.apply }}</span
            >
          </template>
        </el-table-column>

        <el-table-column label="参与报价回收商" slot="merchants" align="center">
          <template slot-scope="{ row }">
            <span
              style="
                text-decoration: underline;
                cursor: pointer;
                color: #0981ff;
              "
              @click="openStoreList(row)"
              >{{ row.merchants }}</span
            >
          </template>
        </el-table-column>

        <el-table-column label="订单状态" slot="orderState" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.orderState == "00"
                ? "待确认"
                : row.orderState == "02"
                ? "待支付"
                : row.orderState == "01"
                ? "已确认"
                : row.orderState == "03"
                ? "已绑码"
                : row.orderState == "04"
                ? "已收货"
                : row.orderState == "05"
                ? "降价收货"
                : row.orderState == "10"
                ? "已取消"
                : row.orderState == "20"
                ? "已作废"
                : "已退回"
            }}</span>
            <!-- 【待确认：00，已确认：01，已绑码：03，已收货：04，降价收货：05】【已取消：10，已作废：20，已退回 30】 -->
          </template>
        </el-table-column>

        <el-table-column label="补拍状态" slot="reState" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.reState == "01"
                ? "待补拍"
                : row.reState == "02"
                ? "已补拍"
                : "未补拍"
            }}</span>
            <!-- 	补拍状态 01 待补拍 02 已补拍 03 未补拍 -->
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>

    <el-dialog
      class="allot-dialog"
      title="申请补拍记录"
      :visible.sync="orderNoShow"
      :close-on-click-modal="false"
      width="1200px"
      @closed="orderNoClose"
    >
      <div style="margin-bottom: 20px">
        <span style="margin-right: 40px"
          >订单编号：{{ this.ApplyByOrderNolist.orderNo }}</span
        >
        <span style="margin-right: 40px"
          >下单时间：{{ this.ApplyByOrderNolist.orderTime }}</span
        >
        <span
          >订单状态：{{
            this.ApplyByOrderNolist.orderState == "00"
              ? "待确认"
              : this.ApplyByOrderNolist.orderState == "01"
              ? "已确认"
              : this.ApplyByOrderNolist.orderState == "02"
              ? "待支付"
              : this.ApplyByOrderNolist.orderState == "03"
              ? "已绑码"
              : this.ApplyByOrderNolist.orderState == "04"
              ? "已收货"
              : this.ApplyByOrderNolist.orderState == "05"
              ? "降价收货"
              : this.ApplyByOrderNolist.orderState == "10"
              ? "已取消"
              : this.ApplyByOrderNolist.orderState == "20"
              ? "已作废"
              : "已退回"
          }}</span
        >
      </div>
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="recorddata"
        :data="this.recordlist"
        :currentPage="recordpageNum || 1"
        :total="recordtotal"
        @handleCurrentChange="recordfrom"
      >
        <el-table-column
          label="待补拍示例图"
          slot="waitSampleImgList"
          align="center"
        >
          <template slot-scope="{ row }">
            <div
              class="images-box"
              v-if="row.waitSampleImgList && row.waitSampleImgList.length > 0"
            >
              <img
                :src="row.waitSampleImgList[0]"
                alt=""
                @click="seeImg(row)"
              />
              <span class="text-down"
                >共{{ row.waitSampleImgList.length }}张</span
              >
            </div>
            <div v-else>暂无图片</div>
          </template>
        </el-table-column>

        <el-table-column label="补拍照片" slot="imgList" align="center">
          <template slot-scope="{ row }">
            <div
              class="images-box"
              v-if="row.imgList && row.imgList.length > 0"
            >
              <img
                :src="row.imgList[0]"
                alt=""
                @click="seeImg(row, row.imgList)"
              />
              <span class="text-down">共{{ row.imgList.length }}张</span>
            </div>
            <div v-else>暂无图片</div>
          </template>
        </el-table-column>
        <el-table-column label="补拍状态" slot="reState" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.reState == "01"
                ? "待补拍"
                : row.reState == "02"
                ? "已补拍"
                : "未补拍"
            }}</span>
            <!-- 	补拍状态 01 待补拍 02 已补拍 03 未补拍 -->
          </template>
        </el-table-column>

        <el-table-column label="报价师补拍备注" slot="remark" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.remark || "--" }}</span>
          </template>
        </el-table-column>

        <el-table-column label="提交补拍时间" slot="makeTime" align="center">
          <template slot-scope="{ row }">
            <span>{{ row.makeTime || "--" }}</span>
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>

    <el-dialog
      class="allot-dialog"
      title="店员主动补拍订单"
      :visible.sync="Activeformsw"
      :close-on-click-modal="false"
      width="1200px"
      @closed="Activeformclose"
    >
      <GlobalTable
        ref="GlobalTable"
        v-loading="loading"
        :columns="Activeformdata"
        :data="this.Activeformlist"
        :currentPage="Activecurrent"
        :total="Activetotal"
        @handleCurrentChange="Activefrom"
      >
        <el-table-column label="订单状态" slot="orderState" align="center">
          <template slot-scope="{ row }">
            <span>{{
              row.orderState == "00"
                ? "待确认"
                : row.orderState == "01"
                ? "已确认"
                : row.orderState == "02"
                ? "待支付"
                : row.orderState == "03"
                ? "已绑码"
                : row.orderState == "04"
                ? "已收货"
                : row.orderState == "05"
                ? "降价收货"
                : row.orderState == "10"
                ? "已取消"
                : row.orderState == "20"
                ? "已作废"
                : "已退回"
            }}</span>
            <!-- 【待确认：00，已确认：01，已绑码：03，已收货：04，降价收货：05】【已取消：10，已作废：20，已退回 30】 -->
          </template>
        </el-table-column>
        <el-table-column label="补拍照片" slot="imgList" align="center">
          <template slot-scope="{ row }">
            <div
              class="images-box"
              v-if="row.imgList && row.imgList.length > 0"
            >
              <img :src="row.imgList[0]" alt="" @click="seeImg(row)" />
              <span class="text-down">共{{ row.imgList.length }}张</span>
            </div>
            <div v-else>暂无图片</div>
          </template>
        </el-table-column>
        <el-table-column label="补拍备注" slot="remark" align="center">
          <template slot-scope="{ row }">
            <el-tooltip
              v-if="row.remark"
              class="item"
              effect="dark"
              :content="row.remark"
              placement="top"
            >
              <div class="Remarks">
              {{ row.remark }}
              </div>
            </el-tooltip>
            <span v-else>--</span>
          </template>
        </el-table-column>
      </GlobalTable>
    </el-dialog>

    <el-dialog
      :close-on-click-modal="false"
      :width="`${1200}px`"
      title="查看参与报价回收商"
      :visible="storeShow"
      @close="storeClose"
      top="15%"
    >
      <!-- :cell-style="rowStyle"
            @cell-click="Bargain" -->
      <div class="img-box">
        <div class="table_list">
          <el-table
            :data="storeList"
            border
            style="width: 100%"
            id="excelOrder"
          >
            <el-table-column
              prop="index"
              type="index"
              label="序号"
            ></el-table-column>
            <el-table-column
              prop="recycleMerchantName"
              label="报价商家"
              align="center"
            >
              <template scope="{ row }">
                <span
                  class="position isdeal"
                  v-if="row.recycleMerchantName == merchantName"
                  >成交</span
                >
                <span>{{ row.recycleMerchantName }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="quotePrice"
              width="100"
              label="报价金额"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="exclusiveTime"
              width="100"
              label="抢单时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="quoteTime"
              width="180"
              label="最新报价时间"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="staffName"
              label="报价人"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="staffMobile"
              label="账号"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="staffType"
              label="角色"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="quoteNum"
              width="100"
              label="报价次数"
              align="center"
            ></el-table-column>
            <el-table-column
              prop="state"
              width="100"
              label="报价状态"
              align="center"
            >
              <template scope="{ row }">
                <span>{{
                  row.state == "00-0"
                    ? "待报价"
                    : row.state == "00-1"
                    ? "报价中"
                    : row.state == "00-2"
                    ? "已报价"
                    : row.state == "01"
                    ? "已确认"
                    : row.state == "10" && row.exclusiveCancelTime != "--"
                    ? `取消抢单${row.exclusiveCancelTime}`
                    : "交易取消"
                }}</span>
              </template>
            </el-table-column>
            <el-table-column
              prop="quoteNote"
              width="100"
              label="报价备注"
              align="center"
            >
              <template scope="{ row }">
                <el-tooltip
                  v-if="row.quoteNote != '--'"
                  class="item"
                  effect="dark"
                  :content="row.quoteNote == '--' ? '' : row.quoteNote"
                  placement="bottom"
                >
                  <span class="quoteNotecss">{{ row.quoteNote }}</span>
                </el-tooltip>

                <span v-else>{{ row.quoteNote }}</span>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
    </el-dialog>

    <el-image-viewer
      zIndex="99999"
      v-if="showViewer"
      :on-close="
        () => {
          showViewer = false;
        }
      "
      :url-list="imgList"
    />

    <div v-if="show" class="yyyy">
      <i class="el-icon-close" @click="iconclose"></i>
     
      <div style="display: flex;flex-direction: row;align-items: center;justify-content: center;margin-bottom:30px">
        <img :src="whlimg" alt="" style="width: 18px; height: 18px;margin-right:6px;margin-top:3px" />{{ btitle }}</div>
      <div class="too">{{ bmessage }}</div>
    </div>

    <div
      v-if="show"
      style="
        z-index: 2049;
        width: 100vw;
        height: 100vh;
        background: #000000;
        opacity: 0.4;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        overflow: auto;
        margin: 0;
      "
    ></div>
  </div>
</template>

<script >
import moment from "moment";
import _api from "@/utils/request";
import ElImageViewer from "element-ui/packages/image/src/image-viewer";
export default {
  components: { ElImageViewer },
  data() {
    return {
      storeShow: false,
      imgList: [],
      showViewer: false,
      demandfinish: {},
      Activeformlist: [],
      Activeformdata: [
        { label: "订单编号", prop: "orderNo" },
        { slotName: "orderState" },
        { label: "下单时间", prop: "orderTime" },
        { label: "主动补拍时间", prop: "extraTime" },
        { slotName: "imgList" },
        { slotName: "remark" },
        // { slotName: "waitSampleImgList" },
      ],
      Activeformsw: false,
      recorddata: [
        { label: "申请补拍时间", prop: "applyTime" },
        { label: "补拍回收商", prop: "merchantName" },
        { label: "补拍报价师", prop: "merchantStaffName" },
        { slotName: "waitSampleImgList" },
        // { label: "报价师补拍备注", prop: "remark" },
        { slotName: "remark" },
        { slotName: "makeTime" },
        // { label: "提交补拍时间", prop: "makeTime" },
        { slotName: "imgList" },
        // { label: "补拍照片", prop: "imgList" },
        { slotName: "reState" }, //补拍状态
      ],
      orderNoShow: false,
      value: "",
      waitforlist: [],
      waitfordata: [
        { label: "订单编号", prop: "orderNo" },
        { label: "下单时间", prop: "orderTime" },
        { slotName: "apply" }, //申请补拍记录
        { slotName: "waitSampleImgList" },
        { slotName: "merchants" }, //参与报价回收商
        { slotName: "orderState" }, //订单状态
        { slotName: "reState" }, //补拍状态
      ],
      options: [
        {
          value: "01",
          label: "待补拍",
        },
        {
          value: "02",
          label: "已补拍 ",
        },
        {
          value: "03",
          label: "未补拍",
        },
      ],
      Peoplebelow: false,
      Catchup: [
        { label: "员工姓名", prop: "staffName" },
        { label: "手机号", prop: "staffMobile" },
        { slotName: "demand" }, //申请补拍
        { label: "待补拍（单）", prop: "wait" },
        { label: "补拍完成（单）", prop: "finish" },
        { label: "未补拍（单）", prop: "undone" },
        { slotName: "initiative" },
      ],
      handleShow: false,
      storesID: null,
      storeList: [],
      redenvellist: [],
      loading: false,
      pageTableLoading: false,
      page: {
        pageNum: 1,
        total: 1,
      },
      btntype: "",
      areaIddata: "",
      areagetAreaSelect: [{ name: "123", id: 12132 }],
      initData: null,
      pickerMinDate: null,
      pickerMaxDate: null,
      day31: 30 * 24 * 3600 * 1000,
      pickerOptions: {
        onPick: ({ maxDate, minDate }) => {
         if (minDate && this.pickerMinDate) {
           this.pickerMinDate = null;
         } else if (minDate) {
           this.pickerMinDate = minDate.getTime();
         }
       },
       disabledDate: (time) => {
         if (this.pickerMinDate) {
           return (time.getTime() > (this.pickerMinDate + this.day31)) || (time.getTime() < (this.pickerMinDate - this.day31));
         }
         return false;
       }
      },
      entryTime: null,
      fivelist: [
        {
          num: 0,
          title: "要求补拍",
          Tips: "统计所有由报价工程师发起过补拍请求的订单数据",
          src: require("./image/yqbp.png"),
          srcs: require("./image/why.png"),
          masgsrc: require("./image/whl.png"),
          color: "#0981FF",
        },
        {
          num: 0,
          title: "待补拍",
          Tips: "已发起补拍请求的订单在未确认状态下，店员还未完成补拍的订单",
          src: require("./image/dbp.png"),
          srcs: require("./image/whe.png"),
          masgsrc: require("./image/whl (1).png"),
          color: "#FF687B ",
        },
        {
          num: 0,
          title: "补拍完成",
          Tips: "在发起补拍的订单中，订单取消或确认时完成补拍的订单",
          src: require("./image/bpwc.png"),
          srcs: require("./image/whs.png"),
          masgsrc: require("./image/whl (2).png"),
          color: "#80C269",
        },
        {
          num: 0,
          title: "未补拍",
          Tips: "在发起补拍的订单中，在取消或确认订单时未完成补拍的订单",
          src: require("./image/wbp.png"),
          srcs: require("./image/whsi.png"),
          masgsrc: require("./image/whl (3).png"),
          color: "#FFAF29 ",
        },
        {
          num: 0,
          title: "主动补拍",
          Tips: "统计店员主动补拍的订单",
          src: require("./image/zdbp.png"),
          srcs: require("./image/whw.png"),
          masgsrc: require("./image/whl (4).png"),
          color: "#21D8C2",
        },
      ],

      formItemList: [
        { slotName: "areaName" },
        { slotName: "stores" },
        { slotName: "timeSelect" },
        { slotName: "btn" },
      ],
      regiondatatitle: [
        { slotName: "areaName" },
        { slotName: "storeName" },
        // { slotName: "storeName" },
        { label: "回收商要求补拍（单）", prop: "demand" },
        { label: "待补拍（单）", prop: "wait" },
        { label: "补拍完成（单）", prop: "finish" },
        { label: "未补拍（单）", prop: "undone" },
        { label: "主动补拍订单（单）", prop: "initiative" },
      ],
      supplementary: [
        {
          key: "name",
          isNumber: true,
          type: "input",
          labelName: "店员姓名",
          placeholder: "请输入店员姓名",
        },
        {
          key: "mobile",
          isNumber: true,
          type: "input",
          labelName: "联系电话",
          placeholder: "请输入联系电话",
        },
      ],
      twodio: [],
      Catchuplist: [],
      applychangedata: [],
      recordlist: [],
      ApplyByOrderNolist: [],
      waitforlistwait: {},
      homepageName: 1,
      homeToale: 0,
      StorepageNum: 1,
      Storetotal: 0,
      StorestaffMobile: "",
      StorestaffName: "",
      waitfortotal: 0,
      waitforpageNum: 0,
      recordtotal: 0,
      recordpageNum: 0,
      Activetotal: 0,
      Activecurrent: 0,
      Activeparams: {},
      companyId: "",
      merchantName: "",
      show: false,
      btitle: "",
      bmessage: "",
      whlimg: "",
      orderType: '01',
      orderTypeOptions:[{
          value: '01',
          label: '由高到低'
        }, {
          value: '02',
          label: '由低到高'
      }]
    };
  },
  created() {
    let startTime = moment().day(moment().day() - 30).format('YYYY-MM-DD 00:00:00') // 当前时间往前推15天的时间
    let endTime = moment().format('YYYY-MM-DD 23:59:59')
    this.entryTime = [startTime,endTime]
    this.companyId = this.$route.query.id;
    _api
      .inspectorAreaSelect({ scope: "00", companyId: this.companyId })
      .then((res) => {
        this.areagetAreaSelect = res.data;
      });
    const params = {
      companyId: this.companyId,
    };
    _api.getStoreSelectList(params).then((res) => {
      if (res.code === 1) {
        this.storeList = res.data;
      }
    });

    // _api.getStoreSelectOrder().then((res) => {
    //   // console.log(res, "1111111111111111111");
    //   this.storeList = res.data;
    // });

    this.handleConfirm();
  },
  mounted() {},
  methods: {
    iconclose() {
      this.show = false;
    },
    openStoreList(e) {
      this.value = ''
      this.merchantName = e.merchantName;
      _api.orderStoreList({ orderNo: e.orderNo }).then((res) => {
        if (res.code == 1) {
          for (const item of res.data) {
            this.handleData(item);
          }
          this.storeList = res.data;
        }
      });
      this.storeShow = true;
    },
    handleData(param) {
      for (const key in param) {
        if (Object.prototype.toString.call(param[key]) === "[object Object]") {
          this.handleData(param[key]);
        } else if (!param[key] || param[key] == undefined || param[key] == "") {
          param[key] = "--";
        }
      }
      return param;
    },
    storeClose() {
      this.storeShow = false;
      this.storeList = [];
    },
    // 查看营业执照
    seeImg(val, v) {
      console.log(val, v);
      this.imgList = [];
      this.showViewer = true;
      if (v) {
        this.imgList.push(...v);
        return;
      }
      if (val.waitSampleImgList) {
        this.imgList.push(...val.waitSampleImgList);
      } else {
        this.imgList.push(...val.imgList);
      }
    },
    handleConfirm(data) {
      console.log(data);
      let startTime = "";
      let endTime = "";
      if (this.entryTime === null) {
        if (!this.btntype) {
          this.btntype = "01";
        }
        startTime = "";
        endTime = "";
      } else {
        this.btntype = "";
        startTime = moment(this.entryTime[0]).format("x");
        endTime = moment(this.entryTime[1]).format("x");
      }
      //   this.storesID = ;
      let params = {
        companyId: this.companyId,
        dateType: this.btntype,
        endTime: endTime,
        orderType: this.orderType,
        inspectorId: this.areaIddata || "",
        pageNum: 1,
        pageSize: 10,
        startTime: startTime,
        storeId: this.storesID ? Number(this.storesID) : null,
      };
      this.twodio = params;
      this.pageTableLoading = true
      _api.photogetCensus(params).then((res) => {
        console.log(res, "---------------------------------");
        this.redenvellist = res.data.ipage.records;
        this.homepageName = res.data.ipage.current;
        this.homeToale = res.data.ipage.total;
        this.fivelist[0].num = res.data.demand;

        (this.fivelist[2].num = res.data.finish),
          (this.fivelist[4].num = res.data.initiative),
          (this.fivelist[3].num = res.data.undone),
          (this.fivelist[1].num = res.data.wait);
        this.pageTableLoading = false
      });
    },
    handleCurrentChange() {},
    btntypeFn(v) {
      this.btntype = v;
      this.entryTime = null;
      this.handleConfirm()
    },
    allotDialogClosed() {
      this.handleShow = false;
    },
    storeNameFn(v) {
      this.handleShow = true;
      // this.twodio.storeId = v;
      let params = this.twodio;
      params.storeId = v;
      params.pageNum=1;
      _api.getStoreStaffCensus(params).then((res) => {
        this.Catchuplist = res.data.records;
        this.StorepageNum = res.data.current;
        this.Storetotal = res.data.total;
      });
    },
    Catchupquery(data) {
      let params = this.twodio;
      params.pageNum=1;
      params.staffName = data.name;
      params.staffMobile = data.mobile;
      this.StorestaffName = data.name;
      this.StorestaffMobile = data.mobile;
      _api.getStoreStaffCensus(params).then((res) => {
        this.Catchuplist = res.data.records;
        this.StorepageNum = res.data.current;
        this.Storetotal = res.data.total;
      });
    },
    demandFn(v, e) {
      
      this.Peoplebelow = true;
      console.log(v);
      // console.log(this.twodio,'-----------------');
      let params = this.twodio;
      if (e) {
        params.reState = e;
        
      } else {
        this.value = '';
        params.reState = "";
        // params.pageNum=1;
      }
      params.pageNum=1;
      params.staffId = v.staffId;
      this.applychangedata = params;
      // console.log(this.applychangedata,'---------------------');
      _api.getStaffWaitOrder(params).then((res) => {
        console.log(res);
        this.waitforlist = res.data.ipage.records;
        this.waitfortotal = res.data.ipage.total;
        this.waitforpageNum = res.data.ipage.current;
        this.waitforlistwait = {
          wait: res.data.wait,
          waitToday: res.data.waitToday,
        };
      });
    },
    Peoplebelowclose() {
      this.Peoplebelow = false;
    },
    applychange(e) {
      console.log(e, "补拍状态");
      this.demandFn(this.applychangedata, e);
    },
    applyclose() {
      this.value = ''
      this.demandFn(this.applychangedata, "");
    },
    applyFn(row) {
      this.value =''
      this.orderNoShow = true;
      // this.orderNo = row.orderNo
      this.ApplyByOrderNolist = row;
      console.log(row);
      _api.getOrderApplyByOrderNo({ orderNo: row.orderNo }).then((res) => {
        this.recordlist = res.data;
        this.recordtotal = this.recordlist.length;
      });
    },
    orderNoClose() {
      this.orderNoShow = false;
    },
    activeFn(row) {
      this.Activeformsw = true;
      console.log(row);
      let params = this.twodio;
            params.pageNum = 1;
      params.staffId = row.staffId;
      this.Activeparams = params;
      _api.getStaffInitiativeOrder(params).then((res) => {
        console.log(res);
        this.Activeformlist = res.data.records;
        this.Activetotal = res.data.total;
        this.Activecurrent = res.data.current;
      });
    },

    Activeformclose() {
      this.Activeformsw = false;
    },
    wenhao(v, o, i) {
      this.show = true;
      this.btitle = v;
      this.bmessage = o;
      this.whlimg = i;
    },

    Homeform(val) {
      console.log(val);
      if (val) {
        this.homepageName = val;
      } else {
        this.homepageName = 1;
      }

      let params = this.twodio;
       params.storeId = this.storesID;
      params.pageNum = this.homepageName;
      _api.photogetCensus(params).then((res) => {
        console.log(res, "---------------------------------");
        this.redenvellist = res.data.ipage.records;
        this.homepageName = res.data.ipage.current;
        this.homeToale = res.data.ipage.total;
        this.fivelist[0].num = res.data.demand;
        (this.fivelist[2].num = res.data.finish),
          (this.fivelist[4].num = res.data.initiative),
          (this.fivelist[3].num = res.data.undone),
          (this.fivelist[1].num = res.data.wait);
      });
    },
    Storeform(val) {
      console.log(val);
      if (val) {
        this.StorepageNum = val;
      } else {
        this.StorepageNum = 1;
      }
      let params = this.twodio;
      params.pageNum = this.StorepageNum;
      params.staffName = this.StorestaffName;
      params.staffMobile = this.StorestaffMobile;

      _api.getStoreStaffCensus(params).then((res) => {
        this.Catchuplist = res.data.records;
        this.StorepageNum = res.data.current;
        this.Storetotal = res.data.total;
      });
    },
    waitforfrom(val) {
      console.log(val);
      if (val) {
        this.waitforpageNum = val;
      } else {
        this.waitforpageNum = 1;
      }
      let params = this.applychangedata;
      params.pageNum = this.waitforpageNum;
      _api.getStaffWaitOrder(params).then((res) => {
        console.log(res);
        this.waitforlist = res.data.ipage.records;
        this.waitfortotal = res.data.ipage.total;
        this.waitforpageNum = res.data.ipage.current;
        this.waitforlistwait = {
          wait: res.data.wait,
          waitToday: res.data.waitToday,
        };
      });
    },
    recordfrom(val) {
      console.log(val);
      if (val) {
        this.recordpageNum = val;
      } else {
        this.recordpageNum = 1;
      }
    },
    Activefrom(val) {
      console.log(val);
      if (val) {
        this.Activecurrent = val;
      } else {
        this.Activecurrent = 1;
      }
      let params = this.Activeparams;
      params.pageNum = this.Activecurrent;
      _api.getStaffInitiativeOrder(params).then((res) => {
        console.log(res);
        this.Activeformlist = res.data.records;
        this.Activetotal = res.data.total;
        this.Activecurrent = res.data.current;
      });
    },
    orderTypeChange(e){
      console.log(e);
      this.handleConfirm()
    }
  },
};
</script>

<style lang="scss" scoped>
.five_style {
  width: 100%;
  margin-bottom: 20px;
  border-radius: 6px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  div {
    display: flex;
    // justify-content: space-between;
    // display: inline-block;
    width: calc((100% - 120px) / 5);
    height: 100px;
    background: #ffffff;
    border-radius: 10px;
    box-sizing: border-box;
    img {
      width: 60px;
      height: 60px;
      margin: 20px 30px;
    }
    div {
      height: 60px;
      // flex: 1;
      width: 100%;
      // width: 100%;
      display: flex;
      flex-flow: column;
      // flex-wrap: wrap;
      font-size: 30px;
      font-family: PingFang SC;
      font-weight: bold;
      color: #0981ff;
      line-height: 30px;
      margin-top: 10px;
      div:nth-child(2) {
        font-size: 18px;
        font-family: PingFang SC;
        font-weight: bold;
        color: #333333;
        line-height: 41px;
        margin-top: 0;
      }
    }
  }
}
.dangqian {
  width: 295px;
  min-width: 295px;
  padding: 10px 20px;
  background-color: rgb(242, 242, 242);
  border-radius: 44px;
  margin-bottom: 20px;
}

.images-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.images-box > img {
  width: 70px;
  height: 70px;
  margin-bottom: 10px;
}
.images-box > span {
  color: #0981ff;
  cursor: pointer;
}
// 弹窗
.img-box {
  margin-bottom: 20px;
  // 图片
  .imgs-num {
    text-align: center;
    margin: 5px 0;
    font-weight: bold;
  }
  .img-tips-box {
    display: flex;
    height: 30px;
    width: max-content;
    border: 1px solid #0981ff;
    .img-tips {
      width: 80px;
      text-align: center;
      line-height: 30px;
      box-sizing: border-box;
      cursor: pointer;
    }
    .img-tips-default {
      background: #fff;
      color: #0981ff;
    }
    .img-tips-choose {
      background: #0981ff;
      color: #fff;
    }
  }
  // 列表
}

.yyyy {
  /* margin: 0 auto; */
  /* margin-top: 15vh; */
  width: 375px;
  height: 192px;
  background: #ffffff;
  box-shadow: 0px 1px 8px 2px rgba(0, 0, 0, 0.13);
  border-radius: 14px;
  position: absolute;
  top: 35vh;
  z-index: 2050;
  /* margin: 0 auto; */
  left: calc(50% - (375px / 2));
  padding-top: 39px;
  box-sizing: border-box;
  // img {
  //   // margin: auto;
  //   margin-top: 39xp;
  //   margin-left: calc(50% - 40px);
  //   margin-bottom: 10px;
  // }
  div {
    font-size: 18px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #333333;
    text-align: center;
  }
  .too {
    font-size: 16px;
    font-family: PingFang SC;
    font-weight: bold;
    color: #666666;
    margin: 20px;
    font-weight: 400;
  }
  i {
    position: absolute;
    top: 14px;
    right: 14px;
  }
}
// 备注说明
.Remarks {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3; //控制行数
  overflow: hidden;
}
</style>
